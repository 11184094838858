<template>
  <div class="random-page" v-if="!isOperator">
    <div class="herosection">
      <div class="filigrane-color" />
      <img
        :src="require('@/assets/images/tourismeBenin/Ganvié.jpg')"
        alt=""
        class="herosection-back-image"
      />
      <h1 style="color: #fff; text-transform: uppercase">
        {{ $t('home.professionnalAccess') }}
      </h1>
      <p class="my-2">{{ $t('home.professionnalAccessDescription') }}</p>
      <div class="btns-actions">
        <b-button
          v-if="isAuthenticated"
          href="/dashboard"
          class="m-0"
          variant="warning"
          >{{ $t('home.professionnalAccessDashboard') }}</b-button
        >
        <b-button
          v-if="!isAuthenticated"
          href="/operateurs/create"
          class="m-0"
          variant="warning"
          >{{ $t('home.access.register') }}</b-button
        >
        <b-button
          v-if="!isAuthenticated"
          class="m-0"
          @click="launchSSO()"
          variant="light"
        >
          {{ $t('home.access.loginSSO') }}
        </b-button>
        <router-link v-if="!isAuthenticated" to="/login">
          <b-button class="m-0" variant="outline-light">{{
            $t('home.access.login')
          }}</b-button>
        </router-link>
      </div>
    </div>

    <!-- <div class="random-page-wrapper">
      <b-card>
        <h2 class="mb-2">Les services dématérialisés</h2>
      </b-card>
    </div> -->
  </div>
</template>

<script>
import { BButton, BCard } from 'bootstrap-vue';
import { mapState, mapActions } from 'vuex';
import utilsService from '@/services/utils/utils.service';

export default {
  components: {
    BButton,
    BCard,
  },
  computed: {
    ...mapState('auth', {
      isAuthenticated: 'isAuthenticated',
    }),
  },
  mounted() {
    const { isOperator, isAgentInterne, isAgentExterne, isAdmin } =
      utilsService.currentUserUtils();
    this.isOperator = isOperator;
  },
  data() {
    return {
      isOperator: false,
      ssoUrlCallback: 'https://tourisme-app.star-labs.bj/admin/sso_callback',
    };
  },
  methods: {
    launchSSO() {
      window.location.href =
        'https://pprodofficial.service-public.bj/official/login?client_id=mtcalicence&redirect_uri=' +
        this.ssoUrlCallback +
        '&response_type=code&scope=openid&authError=true';
    },
  },
};
</script>

<style scoped>
.random-page {
}
.random-page-wrapper {
  max-width: 1250px;
  margin: 0 auto;
  padding: 2em;
}
.herosection {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  text-align: center;

  padding: 6em 2em;
  /* height: 323px; */
}
.herosection h1 {
  font-size: 27px;
}
.herosection-back-image {
  position: absolute;
  object-fit: cover;
  inset: 0;
  z-index: -9;
  width: 100%;
  height: 100%;
}
.herosection .filigrane-color {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: -8;

  background-color: #06250fb2;
}
.btns-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
}
::v-deep .btns-actions button {
  width: 100%;
}

/* MEDIA QUERIES */
@media (min-width: 576px) {
  .herosection h1 {
    font-size: 42px;
  }
  .btns-actions {
    flex-direction: row;
  }
  ::v-deep .btns-actions button {
    width: auto;
  }
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}
@media (min-width: 1400px) {
}
</style>
