<template>
  <div class="hero-section">
    <div class="hero-section-img">
      <!-- <nav class="mega-navigation">
          <ul>
            <li>Reglementation touristique</li>
            <li>Reglementation touristique</li>
            <li>Reglementation touristique</li>
          </ul>
        </nav> -->
      <img
        :src="require('@/assets/images/tourismeBenin/Ganvié.jpg')"
        alt="logo"
        ref="backImages"
      />
      <!-- <img src="/hero.jpg" alt="" class="" /> -->

      <div class="spacer" />
      <h2 ref="heroHeading">
        {{ $t('home.heroTitle') }}
      </h2>
      <!-- <h2 ref="heroHeading">Visitez les magnifiques terres des amazones.</h2> -->
      <div ref="heroWelcome">
        <welcome-message />
      </div>
      <!-- <SearchBox /> -->
    </div>
  </div>
</template>
<script>
import Ripple from 'vue-ripple-directive';
import SearchBox from '@/components/HomeComponents/SearchBox.vue';
import WelcomeMessage from '@/components/HomeComponents/WelcomeMessage.vue';
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';

import {
  BRow,
  BCol,
  BForm,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BButton,
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BCardText,
  BModal,
  BCardImg,
  BCarousel,
  BCarouselSlide,
  BAlert,
} from 'bootstrap-vue';
export default {
  components: {
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BModal,
    BCarousel,
    BCarouselSlide,
    BAlert,
    SearchBox,
    WelcomeMessage,
  },
  computed: {
    ...mapState('auth', {
      isAuthenticated: 'isAuthenticated',
    }),
  },
  data() {
    return {};
  },
  mounted() {
    window.addEventListener('scroll', this.calculateParallaxPosition);
  },
  destroyed() {
    window.removeEventListener('scroll', this.calculateParallaxPosition);
  },
  methods: {
    calculateParallaxPosition() {
      // On récupère la position verticale du scroll
      const scrollY = window.scrollY;

      // On calcule la position verticale de l'avant-plan
      const parallaxPosition = scrollY * 0.5;

      // On définit la position verticale de l'avant-plan
      this.$refs.backImages.style.transform = `translateY(-${
        parallaxPosition * 0.5
      }px) scale(1.5)`;
      this.$refs.heroHeading.style.transform = `translateY(-${
        parallaxPosition * 2
      }px)`;
      this.$refs.heroWelcome.style.transform = `translateY(-${
        parallaxPosition * 1
      }px)`;
      // document.querySelector('.pratak').style.transform = `translateY(-${
      //   parallaxPosition * 3
      // }px)`;
      // document.querySelector('.background').style.top = parallaxPosition + 'px';
    },
  },
};
</script>
<style scoped>
.hero-section {
  width: 100%;
  min-height: 100vh;
  display: flex;
  /* padding-top: 98px; */
  flex-direction: column;
  margin-top: -145.36px;
}
header {
  padding: 1em;
  width: 200px;
}
.hero-section-img {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  /* padding: 115px 20px 20px 20px; */
  /* margin-top: 98px; */
  /* display: none; */
}
.spacer {
  margin-top: 91px;
}
.hero-section-img h2 {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  text-shadow: 2px 2px 2px #00000090;
  max-width: 1200px;
  display: inline-block;
  margin-top: 5em;
  margin-bottom: 1em;
  margin-left: 20px;
  margin-right: 20px;
}
.hero-section-img img {
  position: absolute;
  /* position: fixed; */
  z-index: -10;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.5);
}

/* MEGA NAVIGATION */
.mega-navigation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f8f8f8;
}
.mega-navigation ul {
  display: flex;
  overflow: hidden;
  /* flex-wrap: wrap; */
}
.mega-navigation li {
  padding: 1em;
}

/* MEDIA QUERIES */
@media (min-width: 576px) {
  .spacer {
    margin-top: 130px;
  }
  .hero-section-img h2 {
    font-size: 34px;
    margin-top: 2em;
  }
}
@media (min-width: 768px) {
  .hero-section-img h2 {
    font-size: 44px;
    margin-top: 1em;
    margin-bottom: 0.5em;
  }
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
  /* .hero-section {
    margin-top: -103.39px;
  } */
  .hero-section {
    margin-top: -103.39px;
  }
}
@media (min-width: 1400px) {
}
</style>
