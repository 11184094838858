<template>
  <div>
    <div class="random-page bg-primary">
      <div class="random-page-wrapper">
        <h1 class="text-white">{{$t('sectors_tourism.title')}}</h1>
        <!-- <h1 class="text-white">{{ $t('home.tourismeFieldSectionTitle') }}</h1> -->

        <div class="sectors-indic-list mt-3">
          <!-- <b-skeleton
            animation="wave"
            v-if="isFetchingIndicators"
            height="100px"
            width="100%"
          ></b-skeleton>
          <b-skeleton
            animation="wave"
            v-if="isFetchingIndicators"
            height="100px"
            width="100%"
          ></b-skeleton>
          <b-skeleton
            animation="wave"
            v-if="isFetchingIndicators"
            height="100px"
            width="100%"
          ></b-skeleton> -->
          <div
            class="indicator-box"
            v-for="(item, index) in secteurs_touristiques"
            :key="index"
          >
            <div class="d-flex flex-column">
              <div
                class="d-flex align-items-center"
                style="margin-bottom: 10px"
              >
                <b style="font-size: 18px">{{ $t(item.label) }}</b>
              </div>
            </div>
            <p
              style="
                text-align: justify;
                text-justify: inter-words;
                display: block;
              "
              class="text-light"
            >
              {{ $t(item.description) }}
              <ul>

                <li class="my-1" v-for="(sub_sector, index) in item.sub_sectors" :key="index"><b>{{ $t(sub_sector.label)}}</b>: <br> {{ $t(sub_sector.description) }}</li>
              </ul>
            </p>
          </div>


          <!-- <app-collapse
      accordion
      type="margin"
    >

      <app-collapse-item title="Accordion Item 1">
        Cheesecake cotton candy bonbon muffin cupcake tiramisu croissant. Tootsie roll sweet candy bear claw chupa chups lollipop toffee. Macaroon donut liquorice powder candy carrot cake macaroon fruitcake. Cookie toffee lollipop cotton candy ice cream dragée soufflé. Cake tiramisu lollipop wafer pie soufflé dessert tart. Biscuit ice cream pie apple pie topping oat cake dessert. Soufflé icing caramels. Chocolate cake icing ice cream macaroon pie cheesecake liquorice apple pie.
      </app-collapse-item>
    </app-collapse> -->

          <!-- <div
            class="indicator-box"
            v-if="!isFetchingIndicators && secteurs.length > 0"
            v-for="(item, index) in secteurs"
            :key="index"
          >
            <div class="d-flex flex-column">
        
              <div
                class="d-flex align-items-center"
                style="margin-bottom: 10px"
              >
                <b style="font-size: 18px">{{ item.title }}</b>
              </div>
            </div>
            <span
              style="
                text-align: justify;
                text-justify: inter-words;
                display: block;
              "
              class="text-light"
            >
              {{ item.description }}
            </span>
          </div> -->
        </div>
      </div>
    </div>
    <div class="bg-white pt-3 pb-4">
      <h2 class="text-center pb-3">
        {{ $t('home.tourismeFieldSectionTitleInNumber') }}
      </h2>
      <div class="list-of-stats">
        <div
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 32 32"
          >
            <path
              fill="#0e6258"
              d="M4 3v26h11v-4h2v4h11V3zm2 2h20v22h-7v-4h-6v4H6zm2 2v2h4V7zm6 0v2h4V7zm6 0v2h4V7zM8 11v2h4v-2zm6 0v2h4v-2zm6 0v2h4v-2zM8 15v2h4v-2zm6 0v2h4v-2zm6 0v2h4v-2zM8 19v2h4v-2zm6 0v2h4v-2zm6 0v2h4v-2zM8 23v2h4v-2zm12 0v2h4v-2z"
            />
          </svg>
          <span
            style="
              font-size: 17px;
              font-weight: bold;
              display: inline-block;
              margin: 0.8em 0 0.6em 0;
              text-align: center;
            "
            >{{ $t('sectorsList.hebergement') }}</span
          >
          <b
            style="font-size: 40px; font-weight: 900"
            v-if="secteurs.length > 0"
            >{{ secteurs[0].count }}</b
          >
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 32 32"
          >
            <path
              fill="#0e6258"
              d="M16 3c-1.645 0-3 1.355-3 3v7.344l-8.406 3.75l-.594.25v4.781l9-1v1.844l-2.563 1.718l-.437.282v4.25l1.188-.25L16 28l4.813.969l1.187.25v-4.25l-.438-.282L19 22.97v-1.844l9 1v-4.781l-.594-.25L19 13.344V6c0-1.645-1.355-3-3-3zm0 2c.566 0 1 .434 1 1v8.656l.594.25L26 18.656v1.219l-9-1v5.188l.438.28L20 26.064v.718l-3.813-.75L16 25.97l-.188.062l-3.812.75v-.718l2.563-1.72l.437-.28v-5.188l-9 1v-1.219l8.406-3.75l.594-.25V6c0-.566.434-1 1-1z"
            />
          </svg>
          <span
            style="
              font-size: 17px;
              font-weight: bold;
              display: inline-block;
              margin: 0.8em 0 0.6em 0;
              text-align: center;
            "
            >{{ $t('sectorsList.agenceVoyage') }}</span
          >
          <b
            style="font-size: 40px; font-weight: 900"
            v-if="secteurs.length > 0"
            >{{ secteurs[1].count }}</b
          >
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <!--           <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 32 32"
          >
            <path
              fill="#0e6258"
              d="m12.375 3.813l-.313.843l-8 21.907l-.218.593l1 1l.593-.218l21.907-8l.843-.313l-.218-.875s-1.41-5.629-5.25-9.469s-9.469-5.25-9.469-5.25zm1.219 2.468c1.136.352 4.836 1.586 7.687 4.438c2.852 2.851 4.086 6.55 4.438 7.687l-1.532.563a10.464 10.464 0 0 0-.437-1.407c-.61-1.511-1.746-3.496-3.781-5.53c-2.035-2.036-4.02-3.141-5.532-3.75c-.539-.216-1-.36-1.406-.47zm-1.219 3.407c.336.09.766.218 1.313.437c1.289.52 3.046 1.484 4.874 3.313c.536.535.977 1.05 1.375 1.562A2.004 2.004 0 0 0 18 17a2 2 0 0 0 2 2c.738 0 1.371-.402 1.719-1c.043.102.117.215.156.313c.227.562.348 1.007.438 1.343l-10 3.625A1.483 1.483 0 0 0 11 22.5a1.5 1.5 0 0 0-1.469 1.813l-2.906 1.062l3.5-9.594A2 2 0 0 0 11 16a2 2 0 0 0 .5-3.938zM14 17.5a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3z"
            />
          </svg> -->
          <i class="las la-utensils icons text-primary"></i>
          <span
            style="
              font-size: 17px;
              font-weight: bold;
              display: inline-block;
              margin: 0.8em 0 0.6em 0;
              text-align: center;
            "
            >{{ $t('sectorsList.restaurant') }}</span
          >
          <b
            style="font-size: 40px; font-weight: 900"
            v-if="secteurs.length > 0"
            >{{ secteurs[3].count }}</b
          >
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <!--           <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 32 32"
          >
            <path
              fill="#0e6258"
              d="M12 2C8.145 2 5 5.145 5 9c0 2.41 1.23 4.55 3.094 5.813C4.527 16.343 2 19.883 2 24h2c0-4.43 3.57-8 8-8c1.375 0 2.656.36 3.781.969A8.007 8.007 0 0 0 14 22c0 4.406 3.594 8 8 8c4.406 0 8-3.594 8-8c0-4.406-3.594-8-8-8a7.964 7.964 0 0 0-4.688 1.531a10.134 10.134 0 0 0-1.406-.719A7.024 7.024 0 0 0 19 9c0-3.855-3.145-7-7-7zm0 2c2.773 0 5 2.227 5 5s-2.227 5-5 5s-5-2.227-5-5s2.227-5 5-5zm10 12c3.324 0 6 2.676 6 6s-2.676 6-6 6s-6-2.676-6-6s2.676-6 6-6zm3.281 3.281L22 22.563l-2.281-2.282l-1.438 1.438l3 3l.719.687l.719-.687l4-4z"
            />
          </svg> -->
          <i class="las la-map-marked-alt icons text-primary"></i>

          <span
            style="
              font-size: 17px;
              font-weight: bold;
              display: inline-block;
              margin: 0.8em 0 0.6em 0;
              text-align: center;
            "
            >{{ $t('sectorsList.guideTouristique') }}</span
          >
          <b
            style="font-size: 40px; font-weight: 900"
            v-if="secteurs.length > 0"
            >{{ secteurs[2].count }}</b
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { BBadge, BSkeleton } from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

// Mod
import indicatorsStoreModule from '@/store/indicateurs';

import { mapActions } from 'vuex';
import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils';

export default {
  components: {
    BBadge,
    BSkeleton,
  },
  data() {
    return {
      isFetchingIndicators: false,
      secteurs: [],
      secteurs_touristiques: [
        {
          label: 'sectors_tourism.tourism_label',
          description:
          'sectors_tourism.tourism_description',
          sub_sectors: [
            {
              label: 'sectors_tourism.tourism_guide_label',
              description:
                'sectors_tourism.tourism_guide_description',
            },
            {
              label: 'sectors_tourism.tourism_agence_label',
              description:
                'sectors_tourism.tourism_agence_description',
            },
          ],
        },
        {
          label: 'Hotel.label',
          description:
            'Hotel.description',
          sub_sectors: [
            {
              label: 'Hotel_establishment.label',
              description:
                'Hotel_establishment.description',
            },
          ],
        },
        {
          label: 'Restoration.label',
          description:
            'Restoration.description',
            sub_sectors: [
            {
              label: 'Bar_restaurants.label',
              description:
                'Bar_restaurants.description',
            },
            {
              label: 'Restaurants.label',
              description:
                'Restaurants.description',
            },
            {
              label: 'Lounges.label',
              description:
                'Lounges.description',
            },
            {
              label: 'Cafeteria.label',
              description:
                'Cafeteria.description',
            },
            {
              label: 'Maquis.label',
              description:
               'Maquis.description',
            },
          ]
        },
      ],
    };
  },
  setup() {
    const requiredStoreModules = [
      { path: 'indicators', module: indicatorsStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },
  mounted() {
    this.fetchIndicators();
  },
  methods: {
    ...mapActions('indicators', {
      action_fetchIndicators: 'fetchIndicators',
    }),
    fetchIndicators() {
      this.isFetchingIndicators = true;
      this.action_fetchIndicators()
        .then((response) => {
          this.secteurs = response.data;

          this.isFetchingIndicators = false;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur survenue lors de la récupération des secteurs.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
          this.isFetchingIndicators = false;
        });
    },
  },
};
</script>
<style scoped>
.random-page {
  /* background-color: #0c4704; */
}
.random-page-wrapper {
  max-width: 1150px;
  margin: 0 auto;
  padding: 4em 2em;
}
.sectors-indic-list {
  display: grid;
  gap: 1em;
}
.indicator-box {
  color: #fff;
  border: 1px solid #ffffff70;
  padding: 20px;
  border-radius: 9px;
  background: linear-gradient(
    325.96deg,
    rgba(255, 255, 255, 0.136) 3.6%,
    rgba(255, 255, 255, 0) 37.99%,
    rgba(255, 255, 255, 0.119) 91.56%
  );
  /* transition: 0.5s ease; */
}
/* .indicator-box:hover {
  background: linear-gradient(
    325.96deg,
    rgba(255, 255, 255, 0.18) 91.56%,
    rgba(255, 255, 255, 0) 37.99%,
    rgba(255, 255, 255, 0.229) 3.6%
  );
} */
.list-of-stats {
  display: grid;
  max-width: 1100px;
  margin: 0 auto;
  gap: 2em;
}
.icons {
  font-size: 50px;
}
@media (min-width: 510px) {
  .list-of-stats {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 810px) {
  .list-of-stats {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 920px) {
  .sectors-indic-list {
    /* grid-template-columns: 1fr 1fr; */
  }
}
@media (min-width: 1000px) {
  .list-of-stats {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 1110px) {
  .sectors-indic-list {
    /* grid-template-columns: 1fr 1fr 1fr; */
  }
}
</style>
