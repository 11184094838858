import { BaseService } from './base.service';

export class IndicatorsService extends BaseService {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    super();
  }

  // FAQ QUESTIONS

  createIndicators(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/statistiques/home-page/indicateurs', data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  fetchIndicators(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('/statistiques/home-page/indicateurs', data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  getIndicators(id) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/statistiques/home-page/indicateurs/${id}`, {})
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  updateIndicators(object) {
    const { id, data } = object;
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .put(`/statistiques/home-page/indicateurs/${id}`, data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  deleteIndicators(id) {
    console.log('id :>> ', id);
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .delete(`/statistiques/home-page/indicateurs/${id}`, {})
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
  searchIndicators(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post('/statistiques/home-page/indicateurs/search', data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
}

export default new IndicatorsService();
