<template>
  <div class="search-box">
    <div class="search-box-inputs">
      <div class="search-section">
        <b>Domaine d'activité</b>
        <v-select
          v-model="selectedDomain"
          label="title"
          :options="activityDomainsOptions"
        />
        <!-- <b-form-input
          type="text"
          class="form-control"
          placeholder="Rechercher un opérateur touristique"
        /> -->
      </div>
      <div class="search-section">
        <b>Opérateur touristique</b>
        <!-- v-model="filterBy.lastname" -->
        <b-form-input
          type="text"
          v-model="searchOperator"
          class="form-control"
          debounce="500"
          placeholder="Rechercher un opérateur touristique"
        />
      </div>
    </div>
    <div class="search-section">
      <b>Actions</b>
      <div class="search-actions-btns">
        <b-button block disabled variant="primary">
          <span class="align-middle">Consulter profil</span>
        </b-button>
        <b-button disabled block variant="outline-danger">
          <feather-icon icon="BellIcon" size="16" class="d-inline mr-1" />
          <span class="align-middle">Déposer une réclamation</span>
        </b-button>
      </div>
    </div>
    <div class="colors-bar">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
import {
  BForm,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BButton,
  BCard,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
export default {
  components: {
    BForm,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BButton,
    BCard,
    vSelect,
  },
  data() {
    return {
      searchOperator: '',
      selectedDomain: { title: 'Tout' },
      activityDomainsOptions: [
        { title: 'Tout' },
        { title: 'restauration' },
        { title: 'Hotellerie' },
      ],
    };
  },
};
</script>
<style scoped>
.search-box {
  margin-bottom: 4em;
  padding: 1em;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
}
.search-box-inputs {
  display: flex;
  flex-direction: column;
}
.search-box .btn {
  white-space: nowrap;
  margin: 0;
}
.search-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.search-section b {
  display: inline-block;
  margin-bottom: 5px;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
}
.search-actions-btns {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex-direction: column;
}

.colors-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
  z-index: 50;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.colors-bar div:nth-child(1) {
  background-color: green;
}
.colors-bar div:nth-child(2) {
  background-color: yellow;
}
.colors-bar div:nth-child(3) {
  background-color: red;
}

/* MEDIA QUERIES */
@media (min-width: 576px) {
  /* .search-box {
    flex-direction: row;
    gap: 1em;
  } */
  .search-box-inputs {
    flex-direction: row;
    gap: 1em;
  }
  .search-actions-btns {
    flex-direction: row;
  }
}
@media (min-width: 862px) {
  .search-box {
    flex-direction: row;
    gap: 1em;
  }
  .search-box-inputs {
    flex-direction: row;
    gap: 1em;
  }
  .search-actions-btns {
    flex-direction: row;
  }
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}
@media (min-width: 1400px) {
}

/* MEDIA QUERIES */
/* @media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}
@media (min-width: 1400px) {
} */
</style>
