import IndicatorsService from '@/services/http/indicateurs.service';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // Indicators
    async createIndicators({ rootState }, data) {
      const res = await IndicatorsService.createIndicators(data);
      return res;
    },

    async fetchIndicators({ rootState }, data) {
      const res = await IndicatorsService.fetchIndicators(data);
      return res;
    },

    async getIndicators({ rootState }, data) {
      const res = await IndicatorsService.getIndicators(data);
      return res;
    },

    async updateIndicators({ rootState }, data) {
      const res = await IndicatorsService.updateIndicators(data);
      return res;
    },

    async deleteIndicators({ rootState }, data) {
      const res = await IndicatorsService.deleteIndicators(data);
      return res;
    },
    async searchIndicators({ rootState }, data) {
      const res = await IndicatorsService.searchIndicators(data);
      return res;
    },
  },
};
