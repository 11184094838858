/* eslint-disable global-require */
<template>
  <div>
    <!-- <FormFillingPeriodMessage/> -->
    <!-- message -->
    <hero-section-2 />
    <!-- <hero-section /> -->
    <acteurs-tourisme />
    <indicators-tourisme />
    <div class="blog-section-list">
      <blog-section-skeleton v-if="isLoadingReglementationsPosts" />
      <blog-section
        sectionName="Règlementations"
        :blogPosts="reglementationsPosts"
        v-if="reglementationsPosts.length > 0"
      />
      <blog-section-skeleton v-if="isLoadingChiffresTendances" />
      <blog-section
        v-if="chiffresTendancesPosts.length > 0"
        :sectionName="$t('blogCategories.chiffresTendances')"
        :blogPosts="chiffresTendancesPosts"
      />
      <!-- Blog Investisseurs -->
      <blog-section-skeleton v-if="isLoadingInvestorsPosts" />
      <blog-section
        v-if="investorsPosts.length > 0"
        sectionName="$t('investissors.title')"
        :blogPosts="investorsPosts"
      />
      <!-- Blog Concours -->
      <blog-section-skeleton v-if="isLoadingConcoursPosts" />
      <blog-section
        v-if="concoursPosts.length > 0"
        sectionName="Concours"
        :blogPosts="concoursPosts"
      />
    </div>

    <demandes-section :showAll="!isAuthenticated" />
    <FAQ />
    <CTABottom />

    <!-- <section>
      <b-card>
        <h2>{{ $t('home.title') }}</h2>
        <p class="border-left-primary-5px">
          Au Bénin, le tourisme bénéficie d'un important potentiel naturel,
          historique et culturel et constitue la deuxième source nationale de
          rentrées de devises. Le secteur du tourisme est également le troisième
          employeur du pays, après l’agriculture et le commerce. Cependant, ce
          secteur a manqué de moyens pour son développement. De nouvelles
          perspectives se dessinent eu égard aux ambitions du Gouvernement, avec
          le soutien de ses partenaires au développement notamment le Groupe de
          la Banque, de faire du tourisme un vecteur de croissance et d’emploi.
        </p>
      </b-card>
    </section> -->

    <!-- carousel -->
    <!-- <b-carousel
      id="carousel-interval"
      controls
      indicators
      width="100"
      height="100"
    >
      <b-carousel-slide
        :img-src="require('@/assets/images/tourismeBenin/musee.jpg')"
        caption-html="<span class='text-white'>Le Musée de l'épopée</span>"
        class="card-img-overlay"
      />
      <b-carousel-slide
        :img-src="require('@/assets/images/tourismeBenin/Pendjari 2.jpg')"
        caption-html="<span class='text-white'>Le Parc National de la Pendjari</span>"
        class="card-img-overlay"
      />
      <b-carousel-slide
        :img-src="require('@/assets/images/tourismeBenin/ganvie.jpg')"
        caption-html="<span class='text-white'>Village lacustre de Ganvié</span>"
        class="card-img-overlay"
      />
    </b-carousel> -->

    <!-- <section v-if="canShowForms" class="mt-4">
      <b-card class="userGateDesc2" id="gate-frontieres">
        <p>
          <strong> Questionnaire de renseignement à l'entrée aux frontières terrestres</strong> <br>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="
              createFormulaire({
                name: 'formulaires-create',
                path: '/formulaires/create/fiche-de-renseignement-d\'entrée-des-voyageurs-aux-postes-frontaliers-terrestres',
                params: {
                  slug: 'fiche-de-renseignement-d\'entrée-des-voyageurs-aux-postes-frontaliers-terrestres',
                  title:
                    'Fiche de renseignement d\'entrée des voyageurs aux postes frontaliers terrestres',
                },
              })
            "
          >
            Questionnaire Entrée Frontières terrestres
          </b-button>
        </p>
        <p>
          <strong> Questionnaire de renseignement à la sortie aux frontières terrestres</strong> <br>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="
              createFormulaire({
                name: 'formulaires-create',
                path: '/formulaires/create/fiche-de-renseignement-de-sortie-des-voyageurs-aux-postes-frontaliers-terrestres',
                params: {
                  slug: 'fiche-de-renseignement-de-sortie-des-voyageurs-aux-postes-frontaliers-terrestres',
                  title:
                    'Fiche de renseignement de sortie des voyageurs aux postes frontaliers terrestres',
                },
              })
            "
          >
            Questionnaire Sortie Frontières terrestres
          </b-button>
        </p>
      </b-card>
    </section> -->

    <!-- <section  v-if="!isLoged">
      <div class="cardCustom d-flex justify-content-around ">

        <div
          @click.prevent="scrollTo('gate-aeroport')"
          class="userGateDesc1 text-center">
            <h5 class=" mt-3"> Veuillez vous inscrire si vous être un promoteur d'établissement </h5>  <br>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              @click="createFormulaire({name: 'operateurs-create'})"
            >
              Inscription
            </b-button>
        </div>

        <div
          @click.prevent="scrollTo('gate-aeroport')"
          class="userGateDesc2 text-center">
            <h5 class=" mt-3"> Veuillez vous connecter si vous avez déjà un compte </h5>  <br>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="createFormulaire({name: 'auth-login'})"
            >
              Connexion
            </b-button>
        </div>

        <div
          @click.prevent="scrollTo('gate-aeroport')"
          class="userGateDesc3 text-center">
            <h5 class=" mt-3"> Veuillez définir votre mot de passe si besoin </h5>  <br>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              :to="{
                name: 'etablissements-agents-create-password-agent'
              }"
            >
              Définition Mot de Passe
            </b-button>
        </div>

      </div>
    </section> -->

    <!-- <section id="knowledge-base-content mx-1">
      <div class="my-2 text-center border-dark mt-5 mb-4">
        <h1 class="py-2">
          <strong> Que souhaitez-vous faire ? </strong>
        </h1>
      </div>

      <h2 class="text-dark mb-3 text-center">
        Acteurs des établissements touristiques voulant s'enrôler sur la
        plateforme
      </h2>

      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <b-card class="w-100">
            <div class="m-0">
              <div class="form-item">
                <div class="icon-container">
                  <i class="las la-door-open" />
                </div>
                <div>
                  <h6 class="form-title">Inscription sur la plateforme</h6>
                  <b-card-text class="mt-1">
                    <ul>
                      <li>Cliquer sur le boutton "S'inscrire"</li>
                      <li>
                        Remplir le formulaire d'inscription et le soummettre
                      </li>
                      <li>Valider l'email</li>
                    </ul>
                  </b-card-text>
                </div>
              </div>
            </div>
          </b-card>
        </div>
      </div>

      <h2 class="text-dark mb-3 text-center mt-2">
        Acteurs des établissements touristiques déjà inscrits sur la plateforme
      </h2>

      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12">
          <b-card class="w-100 cardClass">
            <div class="m-0">
              <div class="form-item">
                <div class="icon-container">
                  <i class="las la-utensils" />
                </div>
                <div>
                  <h6 class="form-title">
                    Activités liées aux services de restauration
                  </h6>
                  <b-card-text class="mt-1">
                    <li>Fiches statistiques mensuelles</li>
                  </b-card-text>
                </div>
              </div>
            </div>
          </b-card>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <b-card class="w-100 cardClass">
            <div class="m-0">
              <div class="form-item">
                <div class="icon-container">
                  <i class="las la-map-marked-alt" />
                </div>
                <div>
                  <h6 class="form-title">
                    Activités des agences de voyage et autres services de
                    réservation
                  </h6>
                  <b-card-text class="mt-1">
                    <li>Fiches statistiques mensuelles</li>
                  </b-card-text>
                </div>
              </div>
            </div>
          </b-card>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <b-card class="w-100 cardClass">
            <div class="m-0">
              <div class="form-item">
                <div class="icon-container">
                  <i class="las la-toolbox" />
                </div>
                <div>
                  <h6 class="form-title">Location de matériels de transport</h6>
                  <b-card-text class="mt-1">
                    <li>Fiches statistiques mensuelles</li>
                  </b-card-text>
                </div>
              </div>
            </div>
          </b-card>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <b-card class="w-100 cardClass">
            <div class="m-0">
              <div class="form-item">
                <div class="icon-container">=
                  <i class="las la-hotel" />
                </div>
                <div>
                  <h6 class="form-title">Hébergement pour les voyageurs</h6>
                  <b-card-text class="mt-1">
                    <li>Fiches statistiques mensuelles</li>
                    <li>Fiches clientes</li>
                  </b-card-text>
                </div>
              </div>
            </div>
          </b-card>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <b-card class="w-100 cardClass">
            <div class="m-0">
              <div class="form-item">
                <div class="icon-container">
                  <i class="las la-volleyball-ball" />
                </div>
                <div>
                  <h6 class="form-title">Activités sportives et récréatives</h6>
                  <b-card-text class="mt-1">
                    <li>Fiches statistiques mensuelles</li>
                  </b-card-text>
                </div>
              </div>
            </div>
          </b-card>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <b-card class="w-100 cardClass">
            <div class="m-0">
              <div class="form-item">
                <div class="icon-container">
                  <i class="las la-bus" />
                </div>
                <div>
                  <h6 class="form-title">Agence de voyage</h6>
                  <b-card-text class="mt-1">
                    <li>Fiches statistiques mensuelles</li>
                  </b-card-text>
                </div>
              </div>
            </div>
          </b-card>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12">
          <b-card class="w-100 cardClass">
            <div class="m-0 nopadding">
              <div class="form-item">
                <div class="icon-container">
                  <i class="las la-theater-masks" />
                </div>
                <div>
                  <h6 class="form-title">Activités culturelles</h6>
                  <b-card-text class="mt-1">
                    <li>Fiches statistiques mensuelles</li>
                  </b-card-text>
                </div>
              </div>
            </div>
          </b-card>
        </div>
      </div>
    </section> -->

    <!-- <section>
      <b-row class="mt-3">
        <b-col lg="6" md="12" sm="12">
          <b-card class="cardClass2">
            <h3>
              Acteurs des établissements touristiques voulant s'enrôler sur la
              plateforme
            </h3>
            <hr />
            <p class=" ">
              En tant que promoteur d'établissement des industries touristiques,
              vous avez la possibilité de vous enrôler sur la plateforme en
              effectuant la pré-inscription de votre établissement. Après
              validation de votre email, vous pourrez vous connecter pour
              ajouter des agents et enregistrer vos fiches.
              <br />
              <br />
              Si vous êtes agents d'un établissement des industries
              touristiques, le promoteur de votre établissement devrait vous
              ajouter sur la plateforme. Après validation de votre email et
              définition de votre mot de passe , vous pourrez vous connecter et
              remplir les différents formulaires associés à votre établissement.
              <br />
              <br />
              <strong class="blink-1s"
                >Avis aux promoteurs d'établissement</strong
              >
              <br />
              Lors de l'inscription, il vous sera demandé deux adresses email:
              l'adresse email du promoteur et l'adresse email de
              l'établissement. Il est important de noter que l'adresse email de
              l'etablissement vous sera utile plus tard pour vous connecter à
              votre compte promoteur sur la plateforme.
            </p>

            <div class="mt-1" style="color: #fbfbfb">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                @click="
                  $router.push({
                    name: 'operateurs-create',
                  })
                "
              >
                S'inscrire
              </b-button>
            </div>
            <p />
          </b-card>
        </b-col>

        <b-col lg="6" md="12" sm="12">
          <b-card class="cardClass2">
            <h3>
              Acteurs des établissements touristiques déjà inscrits sur la
              plateforme
            </h3>
            <hr />
            <p class=" ">
              En tant que promoteur ou agent d'établissement des industries
              touristiques déjà inscrits sur la plateforme, vous avez la
              possibilité de remplir la fiche statistique mensuelle de votre
              établissement. <br />
              Le promoteur valide la fiche de statistique mensuelles enregistré
              par eux même ou par les agents habiletés à le faire.
            </p>
            <h3>Formulaires associés</h3>
            <p class=" ">
              Les formulaires associés aux établissements touristiques sont les
              fiches statistiques mensuelles et les fiches clientes si
              diponibles. <br />
              Ces fiches peuvent être retrouvées dans le menu "Formulaire de la
              barre de navigation (nécéssite la connexion des agents acteurs).
            </p>
          </b-card>
        </b-col>
      </b-row>
    </section> -->

    <!--
    <section id="knowledge-base-content">
      <div v-if="formulairesByGroup.length == 0" class="text-center h1 mx-auto">
        Aucun formulaire associé
      </div>
      <b-card v-else class="kb-search-content-info match-height">
        <div
          v-for="(group, index) in formulairesByGroup"
          :key="group.id"
          class="w-100"
        >
          {{ group.name }}
          <div class="form-card-items">
            <div
              v-for="item in group.items"
              :key="item.id"
              md="6"
              sm="12"
              class="form-card-item"
            >
              <tips-modal-create-from-last-record :selected-form-infos="item">
                <template v-slot:actionTrigger="{ onClickOnActionTrigger }">
                  <div class="p-2 m-0">
                    <div class="form-item">
                      <div class="icon-container">
                        <i :class="item.remixIcon || 'ri-file-list-4-fill'" />
                      </div>

                      <div>
                        <h4 class="form-title">
                          {{ item.groupTextualInfos.title }}
                        </h4>
                        <b-card-text class="mt-1">
                          {{ item.groupTextualInfos.description }}
                        </b-card-text>
                        <div class="w-100 text-left">
                          <b-button
                            variant="primary"
                            @click="onClickOnActionTrigger"
                            >Continuer</b-button
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </tips-modal-create-from-last-record>
            </div>
          </div>
          <hr v-if="index + 1 !== formulairesByGroup.length" />
        </div>
      </b-card>
    </section> -->

    <!-- <section>
      <b-row class="match-height">
        <b-col xl="12" md="12">
          <b-card>
            <h1 class="border-left-primary-5px">Statistiques</h1>
            <div class="row">
              <div class="col-md-4 px-1">
                <b-card no-body class="bg-gradient-primary">
                  <b-card-header class="align-items-baseline">
                    <div>
                      <b-card-title class="mb-25 text-white">
                        Chiffre d'Affaire Global
                      </b-card-title>
                      <b-card-text class="mb-0 text-white">
                        2020 Total: <span><strong>7400000 FCFA</strong></span>
                      </b-card-text>
                    </div>
                  </b-card-header>
                  <b-card-body class="pb-0" />
                </b-card>
              </div>
              <div class="col-md-4 px-1">
                <b-card no-body class="bg-gradient-warning">
                  <b-card-header class="align-items-baseline">
                    <div>
                      <b-card-title class="mb-25 text-white">
                        Charge salariale
                      </b-card-title>
                      <b-card-text class="mb-0 text-white">
                        2020 Total:
                        <span class=""><strong>1500000 FCFA</strong></span>
                      </b-card-text>
                    </div>
                  </b-card-header>
                  <b-card-body class="pb-0" />
                </b-card>
              </div>
              <div class="col-md-4 px-1">
                <b-card no-body class="bg-gradient-danger">
                  <b-card-header class="align-items-baseline">
                    <div>
                      <b-card-title class="mb-25 text-white">
                        Autres Dépenses
                      </b-card-title>
                      <b-card-text class="mb-0 text-white">
                        2020 Total:
                        <span class=""><strong>670000 FCFA</strong></span>
                      </b-card-text>
                    </div>
                  </b-card-header>
                  <b-card-body class="pb-0" />
                </b-card>
              </div>
            </div>
            <ecommerce-statistics
              class="p-0 stats-general-card"
              :data="agregats"
            />
          </b-card>
        </b-col>
      </b-row>
    </section> -->

    <!-- alertes infos -->
    <!-- <section>
      <h3 class="my-1">
        Alerte Informations
      </h3>
      <div class="scrollmenu">
        <div>
          <b-alert
            variant="danger"
            show
          >
            <h4 class="alert-heading">
              Enrolement
            </h4>
            <div class="alert-body">
              <span>Lancement de la phase d'enrolement des établissements des industries touristiques ce 12/12/2022  <br> par le Minsitre du Tourisme  et le Premier Ministre</span>
            </div>
          </b-alert>
        </div>
        <div>
          <b-alert
            variant="primary"
            show
          >
            <h4 class="alert-heading">
              Dotation Tourisme
            </h4>
            <div class="alert-body">
              <span>Le Ministère met à dispostion des promoteurs d'établissements nouveaux et anciencs des dotations en fond de roulement. <br>
                L'inscription de fera sur le lien qui suit <strong> <i> <a href="http://google.com">Enrolement</a></i></strong>
              </span>
            </div>
          </b-alert>
        </div>
      </div>
    </section> -->

    <!-- <HomeFooter/> -->
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive';
import {
  BRow,
  BCol,
  BForm,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BButton,
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BCardText,
  BModal,
  BCardImg,
  BCarousel,
  BCarouselSlide,
  BAlert,
} from 'bootstrap-vue';

import utilsService from '@/services/utils/utils.service';

import HeroSection from '@/components/HomeComponents/HeroSection.vue';
import HeroSection2 from '@/components/HomeComponents/HeroSection2.vue';
import FAQ from '@/components/HomeComponents/FAQ.vue';
import BlogSection from '@/components/HomeComponents/BlogSection.vue';
import BlogSectionSkeleton from '@/components/HomeComponents/BlogSectionSkeleton.vue';
import DemandesSection from '@/components/HomeComponents/DemandesSection.vue';
import ActeursTourisme from '@/components/HomeComponents/ActeursTourisme.vue';
import CTABottom from '@/components/HomeComponents/CTABottom.vue';
import IndicatorsTourisme from '@/components/HomeComponents/IndicatorsTourisme.vue';
import TipsModalCreateFromLastRecord from '@/components/TipsModalCreateFromLastRecord.vue';

import localstorageService from '@/services/localstorage/localstorage.service';
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import vSelect from 'vue-select';
import locale from '@core/layouts/components/app-navbar/components/Locale.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
// store modules and vuex utilities
import secteursStroreModule from '@/store/secteurs';
import statisticsStoreModule from '@/store/statistics';
import blogStoreModule from '@/store/blog';
import appConfigurationStroreModule from '@/store/app-configuration';

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils';
import FormFillingPeriodMessage from '@/components/FormFillingPeriodMessage.vue';
import { $themeConfig } from '@themeConfig';
import EcommerceStatistics from '../../dashboard/ecommerce/EcommerceStatistics.vue';

const LayoutDefaultFooter = () => import('@/components/Footer.vue');

export default {
  page() {
    return {
      title: () => this.title,
      meta: [{ name: 'description', content: $themeConfig.app.description }],
    };
  },
  components: {
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BModal,
    BCarousel,
    BCarouselSlide,
    BAlert,
    FormFillingPeriodMessage,

    HeroSection,
    HeroSection2,
    FAQ,
    BlogSection,
    BlogSectionSkeleton,
    DemandesSection,
    ActeursTourisme,
    CTABottom,
    IndicatorsTourisme,

    TipsModalCreateFromLastRecord,
    vSelect,
    locale,
    BCardImg,
    // LayoutDefaultFooter

    EcommerceStatistics,
  },
  directives: {
    Ripple,
  },
  setup() {
    const requiredStoreModules = [
      { path: 'secteurs', module: secteursStroreModule },
      { path: 'statistics', module: statisticsStoreModule },
      { path: 'blog', module: blogStoreModule },
      { path: 'app-configurations', module: appConfigurationStroreModule },
    ];

    // Register module
    registerStoreModule(requiredStoreModules);

    // const formulairesByGroup = utilsService.getListFormulairesByGroup();

    const appLocales = [
      { code: 'en', name: 'anglais' },
      { code: 'fr', name: 'francais' },
    ];

    return { requiredStoreModules, appLocales };
  },
  data() {
    return {
      title: 'Accueil',
      knowledgeBaseSearchQuery: '',
      kb: utilsService.getListFormulaires({
        publicOnly: true,
        
      }),
      kbOptions: {},
      log: false,

      selectedSector: null,
      selectedSectorId: null,
      sectors: null,
      sectorsCustom: [],
      sectorsTotal: null,
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 10,
        metaData: { from: 0, to: 0 },
      },
      agregats: null,

      currentUserId: null,
      canShowForms: null,
      isLoged: null,
      fileUrl:
        'https://mega.nz/file/r1cklLKB#3simXiIx5WSJqaDHUUJLhTPAJ0N2RqXpFEn4BGiUv2E',
      reglementationsPosts: [],
      chiffresTendancesPosts: [],
      concoursPosts: [],
      investorsPosts: [],
      isLoadingReglementationsPosts: false,
      isLoadingChiffresTendances: false,
      isLoadingConcoursPosts: false,
      isLoadingInvestorsPosts: false,
      concoursHandled: false,
      configs: [
        {
          id: null,
          code: 'login.configuration.enable_otp',
          value: false,
        },
        {
          id: null,
          code: 'concours.configuration.enable',
          value: false,
        },
        {
          id: null,
          code: 'config.fedapay.key',
          value: '',
        },
        {
          id: null,
          code: 'config.kkiapay.key',
          value: '',
        },
      ],
    };
  },
  // beforeDestroy() {
  //   unregisterStoreModule(this.requiredStoreModules);
  // },
  computed: {
    ...mapState('auth', {
      isAuthenticated: 'isAuthenticated',
    }),
    // ...mapState('params', {
    //   currentSectorSelected: 'currentSectorSelected',
    // }),
    // ...mapGetters('params', {
    //   getter_currentSectorSelected: 'getter_currentSectorSelected'
    // }),
    filteredKB() {
      const knowledgeBaseSearchQueryLower =
        this.knowledgeBaseSearchQuery.toLowerCase();
      // if(this.selectedSectorId){ // filtre choix de secteur
      return this.kb.filter((item) => {
        const condition =
          (this.selectedSectorId
            ? item.sector == this.selectedSectorId
            : true) &&
          item.title.toLowerCase().includes(knowledgeBaseSearchQueryLower);

        return condition;
      });
    },
    currentLocaleName() {
      if (this.$i18n.locale === 'fr') return 'francais';
      return 'anglais';
    },
    // ...mapState('params', {
    //   currentSectorSelected: 'currentSectorSelected',
    // }),
  },
  watch: {
    selectedSector: {
      handler(val, old) {
        this.selectedSectorId = this.selectedSector._id
          ? this.selectedSector._id
          : null;
        this.filteredKB;
      },
    },
    $route: {
      immediate: true,
      async handler(val, old) {
        const { failed_check_mail } = val.params;
        if (failed_check_mail) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: failed_check_mail,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        }
        return;
        // this.loadAgregats();
        const { currentUserId, userRole } = utilsService.currentUserUtils();
        this.currentUserId = currentUserId;
        if (this.currentUserId) {
          // if(userRole.code == "agent_police"){
          //   this.canShowForms = true
          // }
          if (currentUserId) {
            this.isLoged = true;
            if (userRole.code == 'agent_police') {
              this.canShowForms = true;
            }
          }
        } else {
          // console.log('no log')
        }
      },
    },
  },
  mounted() {
    this.loadReglementationsBlog();
    this.loadChiffreTendancesBlog();
    this.loadInvestisseursBlog();
    this.loadDataAppConfiguration();
    // if (localstorageService.getUserId()) {
    //   this.kbOptions = { publicOnly: false, privateOnly: true };
    // } else {
    //   this.kbOptions = { publicOnly: true, privateOnly: false };
    // }
    // this.loadSectors();
  },
  methods: {
    ...mapActions('secteurs', {
      fetchSecteurs: 'fetchSecteurs',
      addSecteurs: 'addSecteurs',
    }),
    ...mapActions('statistics', {
      fetchStatisticsTable: 'fetchStatisticsTable',
      fetchAgregats: 'fetchAgregats',
    }),
    ...mapActions('blog', {
      action_fetchPosts: 'fetchPosts',
    }),
    // import actions
    ...mapActions('app-configurations', {
      action_getAppConfiguration: 'getAppConfiguration',
      action_searchAppConfiguration: 'searchAppConfiguration',
      action_updateAppConfiguration: 'updateAppConfiguration',
    }),
    scrollTo(el) {
      const htmlElement = document.getElementById(el);
      if (!htmlElement) return;

      const bodyRect = document.body.getBoundingClientRect();
      const elemRect = htmlElement.getBoundingClientRect();
      const offset = elemRect.top - bodyRect.top;
      window.scrollTo({
        top: offset - 100 - (this.isAuthenticated ? 50 : 0),
        behavior: 'smooth',
      });
    },
    createFormulaire(data) {
      // let data = {
      //     name: "formulaires-create",
      //     path: "/formulaires/create/questionnaire-de-renseignements-à-l'entrée-des-aéroports-du-bénin",
      //     params: {
      //         slug: "questionnaire-de-renseignements-à-l'entrée-des-aéroports-du-bénin",
      //         title: "Questionnaire de renseignements à l'entrée des aéroports du Bénin"
      //     }
      // }

      const { params } = data;

      this.$router.push({
        ...data,
        params: {
          ...params,
        },
      });
    },

    loadDataAppConfiguration() {
      this.isLoadingConcoursPosts = true;
      this.action_searchAppConfiguration({
        code: 'concours.configuration.enable',
      })
        .then((response) => {
          this.concoursHandled = response.data.data[0].value;
          this.loadConcoursBlog();
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },
    loadAgregats() {
      const params = {
        page: this.paginationData.currentPage - 1,
        perPage: this.paginationData.perPage,
      };
      this.fetchAgregats(params)
        .then((res) => {
          if (res.status == 200) {
            this.agregats = res.data.resources;
          }
        })
        .catch((err) => {
          // console.log(err)
        });
    },
    loadSectors() {
      const currentParams = {
        page: this.paginationData.currentPage - 1,
        perPage: this.paginationData.perPage,
      };
      this.fetchSecteurs({ params: currentParams || null })
        .then((res) => {
          this.sectors = res.data.secteurs;
          this.sectorsTotal = res.data.total;
          this.sectors.forEach((currentValue, index, arr) => {
            this.sectorsCustom.push({
              nom: currentValue.nom,
              _id: currentValue._id,
            });
          });
        })
        .catch((err) => {
          this.isLoadingTableData = false;
          // console.log(err)
        });
    },
    loadReglementationsBlog() {
      this.isLoadingReglementationsPosts = true;
      this.action_fetchPosts({ type: 'rules' })
        .then((response) => {
          this.reglementationsPosts = response.data.data;

          this.isLoadingReglementationsPosts = false;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur survenue lors de la récupération des articles.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
          this.isLoadingReglementationsPosts = false;
        });
    },
    loadChiffreTendancesBlog() {
      this.isLoadingChiffresTendances = true;
      this.action_fetchPosts({ type: 'stats' })
        .then((response) => {
          this.chiffresTendancesPosts = response.data.data;

          this.isLoadingChiffresTendances = false;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur survenue lors de la récupération des articles.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
          this.isLoadingChiffresTendances = false;
        });
    },
    loadInvestisseursBlog() {
      this.isLoadingInvestorsPosts = true;
      this.action_fetchPosts({ type: 'investors' })
        .then((response) => {
          this.investorsPosts = response.data.data;

          this.isLoadingInvestorsPosts = false;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur survenue lors de la récupération des articles.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
          this.isLoadingInvestorsPosts = false;
        });
    },
    loadConcoursBlog() {
      if (this.concoursHandled === false) {
        this.concoursPosts = [];
        this.isLoadingConcoursPosts = false;
        return;
      }
      this.action_fetchPosts({ type: 'concours' })
        .then((response) => {
          this.concoursPosts = response.data.data;

          this.isLoadingConcoursPosts = false;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur survenue lors de la récupération des articles.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
          this.isLoadingConcoursPosts = false;
        });
    },
    downloadFile() {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', this.fileUrl);
      xhr.responseType = 'blob';
      xhr.onload = () => {
        const blob = xhr.response;
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'file.pdf';
        link.click();
      };
      xhr.send();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/vue/pages/page-knowledge-base.scss';
body,
html {
  scroll-behavior: smooth;
}
.form-title {
  &::first-letter {
    text-transform: uppercase;
  }
}
.form-item {
  // cursor: pointer;
  display: flex;
  gap: 15px;
  align-items: flex-start;
}
.icon-container {
  min-height: 50px;
  height: 50px;
  min-width: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  background-color: rgba($color: $primary, $alpha: 1);
  border-radius: 100px;
  i {
    color: white;
  }
}

.form-card-items {
  display: grid;
  grid-template-columns: 1fr 1fr;

  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
    grid-template-columns: 1fr;
  }
  .form-card-item {
  }
}

.border-left-primary-5px {
  // &::before{
  //   content: "";
  // height: 100%;

  // }
  border-left: 5px solid $primary;
  padding: 10px 5px;
  margin: 20px 0;
}
.stats-general-card {
  .card,
  .card-body,
  .card-header {
    padding: 0 !important;
  }
}
h3 {
  color: #fbfbfb;
}

.userGates {
  display: flex;
  margin: 50px 0px;
  flex-wrap: wrap;
  row-gap: 20px;

  .userGate1 {
    padding: 2px;
    border-radius: 10%;
    height: 350px;
    width: 350px;
    border: 3px solid #0e6258;
    // display: flex;
    // align-items: center;
    justify-content: center;
    font-size: 2rem;
    text-transform: capitalize;
    cursor: pointer;
    text-align: center;
    // box-shadow: 10px 5px 5px red;
  }
  .userGate2 {
    padding: 2px;
    border-radius: 10%;
    height: 350px;
    width: 350px;
    border: 3px solid #fccd5d;
    // display: flex;
    // align-items: center;
    justify-content: center;
    font-size: 2rem;
    text-transform: capitalize;
    cursor: pointer;
    text-align: center;
    // box-shadow: 10px 5px 5px red;
  }
  .userGate3 {
    padding: 2px;
    border-radius: 10%;
    height: 350px;
    width: 350px;
    border: 3px solid #f3706a;
    // display: flex;
    // align-items: center;
    justify-content: center;
    font-size: 2rem;
    text-transform: capitalize;
    cursor: pointer;
    text-align: center;
    // box-shadow: 10px 5px 5px red;
  }
}

.cardCustom {
  display: flex;
  margin: 50px 0px;
  flex-wrap: wrap;
  row-gap: 20px;
}

.userGateDesc1 {
  border: 3px solid #0e6258;
  border-radius: 20px;
  padding: 10px;
  font-size: 1rem;
}
.userGateDesc2 {
  border: 3px solid #fccd5d;
  border-radius: 20px;
  padding: 10px;
  font-size: 1rem;
}
.userGateDesc3 {
  border: 3px solid #f3706a;
  border-radius: 20px;
  padding: 10px;
  font-size: 1rem;
}

.shadow-green:hover {
  box-shadow: 10px 1px 5px #0e6258;
}

.zoom:hover {
  transform: scale(
    1.1
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

// for horizontal scrolling

div.scrollmenu {
  // background-color: #333;
  color: #000;
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu div {
  display: inline-block;
  // color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}
div.scrollmenu div:hover {
  // background-color: #777;
}

// for carousel
.carouselStyle {
  height: 1000px;
}

::v-deep .carousel-item {
  img {
    height: 500px;
    object-fit: cover;
  }
}

.cardClass {
  // height: auto
  height: auto;
}
@media (min-width: 1400px) {
  // xll
  .cardClass {
    height: 120px;
  }
}
@media (min-width: 1200px) {
  // xl
  .cardClass {
    height: 120px;
  }
}
@media (min-width: 992px) {
  // lg
  .cardClass {
    height: 120px;
  }
}
@media (min-width: 768px) {
  // md
  .cardClass {
    height: auto;
  }
}
@media (min-width: 576px) {
  // sm
  .cardClass {
    height: auto;
  }
}

.cardClass2 {
  background-color: #0e6258;
  color: #fbfbfb;
  height: auto;
  font-size: 1em;
}
@media (min-width: 1200px) {
  // lg
  .cardClass2 {
    min-height: 380px;
  }
}
// @media (min-width: 992px) { // md
//   .cardClass2{
//     height: 380px;
//   }
// }

.cardTextSize {
  // height: 1000px;
  font-size: 0.9em;
}
::v-deep .blog-section-list .blogsec:nth-child(even) {
  background-color: red;
}
</style>
