<template>
  <div class="d-flex flex-column align-items-center justify-content-center">
    <div class="mb-2 cta-button-hero">
      <router-link to="#make-demande">
        <b-button variant="warning">{{ $t('home.makeDemandBtn') }}</b-button>
      </router-link>
      <router-link to="/espaceInvestisseur">
        <b-button variant="primary">{{ $t('home.wantToInvestBtn') }}</b-button>
      </router-link>
    </div>
    <div v-if="show" class="alert-box">
      <b-card no-body class="p-2">
        <h4 class="alert-heading">{{ $t('home.welcomeCardTitle') }}</h4>
        <p class="text-dark text-justify">
          {{ $t('home.welcomeCardText') }}
        </p>
        <!-- <hr class="mb-1 mt-0" /> -->
        <div class="d-flex justify-content-end">
          <b-button
            @click="() => (this.show = false)"
            variant="primary"
            size="sm"
            style="margin-right: 8px"
            >{{ $t('home.welcomeCardOk') }}</b-button
          >
          <b-button
            @click="handleMessageAlert()"
            variant="outline-primary"
            size="sm"
            >{{ $t('home.welcomeCardOkIndefinitely') }}</b-button
          >
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { BAlert, BCard, BButton } from 'bootstrap-vue';
import localstorageService from '@/services/localstorage/localstorage.service';
// import { mapState, mapActions } from 'vuex';

export default {
  components: {
    BAlert,
    BCard,
    BButton,
  },
  // computed: {
  //   ...mapState('auth', {
  //     isAuthenticated: 'isAuthenticated',
  //   }),
  // },
  mounted() {
    if (localstorageService.getWelcomePage()) {
      if (!Boolean(JSON.parse(localstorageService.getWelcomePage()))) {
        this.show = false;
      } else {
        this.show = true;
      }
    } else {
      localstorageService.setWelcomePage(true);
    }
  },
  data() {
    return {
      show: true,
    };
  },
  methods: {
    handleMessageAlert() {
      localstorageService.setWelcomePage(false);
      this.show = false;
    },
  },
  created() {},
};
</script>

<style scoped>
.alert-box {
  margin-left: 2em;
  margin-right: 2em;
  /* max-width: 806px; */
  max-width: 1200px;
  overflow: hidden;
  border-radius: 9px;
  margin-bottom: 2em;
  /* border: 1px solid grey; */
}
/* .card {
  height: 100%;
} */
.mirror {
  position: relative;
  background-color: #00000050;
  color: #fff;
  text-shadow: 2px 2px 1px #00000090;
}
.mirror::after {
  z-index: -1;
  position: absolute;
  content: '';
  inset: 0;
  background: linear-gradient(
    325.96deg,
    rgba(0, 0, 0, 0.772) 3.6%,
    rgba(255, 255, 255, 0) 37.99%,
    rgba(0, 0, 0, 0.663) 91.56%
  );
  filter: blur(4px);
}
.cta-button-hero {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
@media (min-width: 437px) {
  .cta-button-hero {
    flex-direction: row;
  }
}

/* .mirror * {
  isolation: isolate;
  filter: blur(0px) !important;
} */
</style>
