<template>
  <div class="section-box">
    <div class="section-box-wrapper">
      <h1 class="mb-1 text-dark">
        {{ $t('home.structuresInCharge') }}
      </h1>
      <!-- <p>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Vero impedit
        error assumenda neque temporibus soluta delectus excepturi, quisquam
        fugiat quis.
      </p> -->
      <div class="section-list mt-3">
        <b-card class="border-primary">
          <div class="actor-header">
            <b-img
              style="width: 130px; object-fit: contain"
              :src="require('@/assets/images/tourismeBenin/anpt.png')"
              alt="logo"
            />
            <div>
              <span>{{ $t('home.nameANPT') }}</span>
            </div>
          </div>
          <br />
          <span
            style="
              text-align: justify;
              text-justify: inter-words;
              display: block;
            "
            >{{ $t('home.descANPT') }}</span
          >
        </b-card>
        <b-card class="border-primary">
          <div class="actor-header">
            <b-img
              style="width: 110px; object-fit: contain"
              :src="require('@/assets/images/logo/logo_mtca.png')"
              alt="logo"
            />
            <div>
              <span>{{ $t('home.nameDTT') }}</span>
            </div>
          </div>
          <br />
          <span
            style="
              text-align: justify;
              text-justify: inter-words;
              display: block;
            "
            >{{ $t('home.descDTT') }}</span
          >
        </b-card>
        <b-card class="border-primary">
          <div class="actor-header">
            <b-img
              style="width: 28px; object-fit: contain"
              :src="require('@/assets/images/tourismeBenin/trone-ghezo.png')"
              alt="logo"
            />
            <div>
              <span>{{ $t('home.nameBeninTourisme') }}</span>
            </div>
          </div>
          <br />
          <span
            style="
              text-align: justify;
              text-justify: inter-words;
              display: block;
            "
            >{{ $t('home.descBeninTourisme') }}</span
          >
        </b-card>
        <!-- <b-card class="border-primary">
          <div class="actor-header">
            <div>
              <b>Agence National des Patrimoines Touristiques (ANPT)</b> <br />
            </div>
          </div>
          <br />
          <span
            >L’ANPT a pour mission de faire du Bénin une des destinations phares
            en Afrique de l’Ouest. Pour cela, son rôle est de mettre en valeur
            le patrimoine naturel, historique et culturel.</span
          >
        </b-card> -->
        <!-- <b-card class="border-primary">
          <div class="actor-header">
            <div><b>Bénin Tourisme</b> <br /></div>
          </div>
          <br />
          <span
            >Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Reiciendis iste nam illum debitis eveniet rem!. Reiciendis iste nam
            illum debitis eveniet rem!</span
          >
        </b-card>
        <b-card class="border-primary">
          <div class="actor-header">
            <div><b>Direction Technique du Tourisme (DTT)</b> <br /></div>
          </div>
          <br />
          <span
            >Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Reiciendis iste nam illum debitis eveniet rem!. Reiciendis iste nam
            illum debitis eveniet rem!</span
          >
        </b-card> -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BCard,
  BAvatar,
  VBTooltip,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue';
import utilsService from '@/services/utils/utils.service';
import vSelect from 'vue-select';

export default {
  components: {
    vSelect,
    BCard,
    BAvatar,
    BImg,
    VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {};
  },
  // isAuthenticated && isInvestisor
  mounted() {},
};
</script>

<style scoped>
.section-box {
  background-color: #f8f8f8;
  /* background-color: #e8f1ea; */
}
.section-box-wrapper {
  padding: 3em 3em 4em 3em;
  max-width: 1200px;
  margin: 0 auto;
}
.section-list {
  display: grid;
  gap: 1em;
}
.section-list .card {
  height: 100%;
}

.actor-header {
  display: flex;
  height: 78px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: 1em;
}
.actor-header span {
  font-weight: bold;
}
@media (min-width: 700px) {
  .section-list {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 1110px) {
  .section-list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
</style>
